import { inject, Injectable } from '@angular/core';
import { CurrentUserService } from '@rma/accounts/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { ShortlistResult } from '../domain/shortlist';
import { ApiShortlistStorageService } from './api-shortlist-storage.service';
import { LocalShortlistStorageService } from './local-shortlist-storage.service';
import { ShortlistStorageService } from './shortlist-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ShortlistService {
  private readonly userService = inject(CurrentUserService);
  private readonly localShortlistStorage = inject(LocalShortlistStorageService);
  private readonly apiShortlistStorage = inject(ApiShortlistStorageService);
  private readonly shortlistAgentCodesSource = new BehaviorSubject<string[]>([]);

  public readonly shortlistCount$: Observable<number>;
  public readonly shortlist$: Observable<string[]>;

  private shortListStorage: ShortlistStorageService;

  public constructor() {
    this.shortlist$ = this.shortlistAgentCodesSource.asObservable();
    this.shortlistCount$ = this.shortlist$.pipe(map((x) => x.length));

    this.shortListStorage = this.localShortlistStorage;

    this.userService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.shortListStorage = isAuthenticated ? this.apiShortlistStorage : this.localShortlistStorage;
      this.replace(this.shortListStorage.load());
    });
  }

  public remove(agentCode: string): Observable<ShortlistResult> {
    return this.shortListStorage.remove(agentCode).pipe(
      tap((result) => {
        if (result) {
          this.shortlist = this.shortlist.filter((code) => code !== agentCode);
        }
      }),
    );
  }

  public toggleShortList(agentCode: string): Observable<ShortlistResult> {
    const isShortlisted = this.shortlist.some((code) => code === agentCode);
    if (isShortlisted) {
      return this.remove(agentCode);
    } else {
      return this.add(agentCode);
    }
  }

  private replace(agentCodes$: Observable<string[]>) {
    agentCodes$.pipe(take(1)).subscribe((agentCodes) => (this.shortlist = agentCodes));
  }

  private add(agentCode: string): Observable<ShortlistResult> {
    return this.shortListStorage.add(agentCode).pipe(
      tap((result) => {
        if (result.success) this.shortlist = [...this.shortlist, agentCode];
      }),
    );
  }

  private get shortlist(): string[] {
    return this.shortlistAgentCodesSource.getValue();
  }

  private set shortlist(val: string[]) {
    this.shortlistAgentCodesSource.next(val);
  }
}
